import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const RelatedPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },limit : 4)  {
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          blogPara
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 720)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="related_posts pt-lg-5 pt-sm-5">
      <div className="posts-title pb-3">
        <span>RELATED POSTS</span>
      </div>
      <div className="row gx-sm-3">
        {data.allStrapiPost.nodes.slice(0, 4).map((e, i) => {
          return (
            <div className="col-lg-6 col-xl-3 col-sm-6 pt-sm-4" key={i}>
              <div className="posts_border pe-xl-3">
                <Link to={`/${e.slug}/`}>
                  <div className="posts_image w-100">
                    <GatsbyImage
                      image={
                        e.image.localFile?.childImageSharp?.gatsbyImageData
                      }
                      style={{
                        height: "100%",
                        width: "100%",
                        minHeight: "200px",
                      }}
                      imgStyle={{ height: "100%" }}
                      alt="reviews-content"
                      loading="eager"
                      className="img-fluid"
                      objectFit="cover"
                    />
                    <div className="read-more">
                      <div className="text">READ MORE</div>
                    </div>
                  </div>
                </Link>
                <ul className="d-flex post_categories pt-4 ">
                  {e.blog_categories &&
                    e.blog_categories.map((a, i) => {
                      return (
                        <Link key={i}
                          to={`/category/${a.category.toLowerCase()}/`}
                          className="py-3"
                        >
                          <li>{a.category}</li>
                        </Link>
                      );
                    })}
                  <div className="august py-3">
                    <span>{e.date}</span>
                  </div>
                </ul>
                <div className="post_content pt-1">
                  <h2 className="pb-2">
                    <Link
                      to={`/${e.slug}/`}
                      dangerouslySetInnerHTML={{ __html: e.Title }}
                    ></Link>
                  </h2>
                  <p className="pt-2">{e.blogPara}</p>
                  {/* <ul className="d-flex admin-content pb-4">
                    <div className="august ">
                      <span>{e.date}</span>
                    </div>
                    <li>
                      <Link to={`/${e.admin_users[0].username.replace(" ", "-")}/`}>
                        <small>
                          by
                          <span className="ps-1 fw-bold" >
                            {e.admin_users[0].username}
                          </span>
                        </small>
                      </Link>
                    </li>
                    <li className="shares d-flex justify-content-center align-items-center ms-lg-3"></li>
                  </ul> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default React.memo(RelatedPosts);